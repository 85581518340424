import React from 'react';
import autoBind from 'react-autobind';
import './footer.css'

class Footer extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }
  
  render() {
    return (
      <div id='dai-advisors-footer'>
        <ul id='dai-advisors-footer-list'>
          <li>
            <a href="form-crs.pdf">
              CRS
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="DAI_Advisors_BCP_2022_filed.pdf">
              BCP
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="/privacy-policy" >
              Privacy Policy
            </a>
          </li>
        </ul>
        <p id='copyright'>
          Copyright © Dependable Alternative Investments, LLC. All Rights Reserved.
        </p>
      </div>)
  }
}

export default Footer;