import React from 'react';
import { 
  Home, 
  About, 
  Footer, 
  Contact, 
  Services,
  Privacy,
} from './components';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <div id="dai-advisors-container">
          <Route exact path='/' component={Home}/>
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/services' component={Services} />
          <Route path='/privacy-policy' component={Privacy} />
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
