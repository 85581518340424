import React from 'react';
import { NavBar } from '../';
import './home.css';

class Home extends React.Component {
  
  render() {
    return (
      <div className='dai-advisors-page'>
        <NavBar highlight='HOME' />
        <div id='dai-advisors-home'>
          <div id='home-splash'>
            <div className='home-splash-title'>
              <div>Welcome to the world of</div>
              <div>Dependable Alternative Investments</div>
            </div>
          </div>
          <div className='home-investment-advisory'>
            <h3 id='home-investment-advisory-text'>
              Are you stressed about the volatility of the stock market? 
              Do you have a need to have a stable investment income? 
              Would you be love to learn some tax saving strategies? 
              Have you ever thought about the best way to grow your net worth steadily? 
              Is it important for you to make sure your fortune taken care after you leave this world? 
              If your answer to any of the above questions is yes, please
              <a id='home-contact-link' href='/contact' > contact us</a>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;