import React from 'react';
import autoBind from 'react-autobind';
import swal from 'sweetalert';
import * as emailjs from 'emailjs-com';
import { NavBar } from '../';
import './contact.css';


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      reason: 'ADVISORY',
      sendButtonText: 'SEND',
      renderNameRequired: false,
      renderEmailRequired: false,
      renderMessageRequired: false
    }
    autoBind(this);
  }

  handleTextInputChange(e, labelText) {
    let input = e.target.value;
    switch(labelText) {
      case 'NAME':
        this.setState({name: input});
        break;
      case 'EMAIL':
        this.setState({email: input});
        break;
      case 'PHONE NUMBER (OPTIONAL)':
        this.setState({phone: input});
        break;
    }
  }

  handleTextAreaChange(e) {
    let input = e.target.value;
    this.setState({message: input});
  }

  handleSelectInputChange(e) {
    let input = e.target.value;
    this.setState({reason: input})
  }

  renderTextInput(labelText, required) {
    let { email, name, phone } = this.state;
    let value;
    switch(labelText) {
      case 'NAME':
        value = name;
        break;
      case 'EMAIL':
        value = email;
        break;
      case 'PHONE NUMBER (OPTIONAL)':
        value = phone;
        break;
    }

    return (
      <div className='contact-form-input'>
        <label className='contact-form-label'>{labelText}</label>
        <input 
          type='text' 
          className="form-control contact-input"
          value={value}
          required={required ? 'required' : ''}
          onChange={(e) => this.handleTextInputChange(e, labelText)}
        />
      </div>
    );
  }

  handleSubmitClick(e) {
    e.preventDefault();

    let { name, email, phone, message, reason} = this.state;
    let reasonText;
    switch(reason) {
      case 'ACCREDITATION':
        reasonText = 'I am interested in accreditation service';
        break;
      case 'ADVISORY':
        reasonText = 'I am interested in investment advisory service';
        break;
      case 'OTHER':
        reasonText  = 'I have other questions';
        break;
    }

    this.setState({sendButtonText: 'Sending...'});
    debugger;
    emailjs.send(
      "default_service",
      'daiadvisors_contact',
      {
        "name": name,
        "email": email,
        "phoneNumber": phone,
        "reason": reasonText,
        "message": message
      },
      'user_641nxrfZtqhxvgsqz1nrj'
    ).then((response) => {
      this.setState({
        name: '',
        email: '',
        phone: '',
        reason: 'ADVISORY',
        message: '',
        sendButtonText: 'SEND'
      });
      swal("Thank you!", "Your message has been sent!", "success");
   }, (error) => {

   });
  }

  validateContactForm() {

  }
  
  render() {
    let {
      message,
      reason,
      sendButtonText
    } = this.state;

    return (
      <div>
        <NavBar highlight='CONTACT' />
        <div className='dai-advisors-page' id='dai-advisors-contact'>
          <div className='splash-img' id='contact-splash-img'>
            <h1 className='splash-img-title'>Contact Us</h1>
          </div>
          <div className='contact-form-container'>
            <div className='contact-form'>
              <h4 id='contact-form-info'>
                If you have questions or are interested in using our services, either for investor accreditation or investment advisory services, please fill out the form below. We will respond to you by email or phone. We look forward to serving you!
              </h4>
              <form
                id='contact-form'
                onSubmit={this.handleSubmitClick}
              >
                {this.renderTextInput('NAME', true)}
                {this.renderTextInput('EMAIL', true)}
                {this.renderTextInput('PHONE NUMBER (OPTIONAL)', false)}
                <div className='contact-form-input'>
                  <label className='contact-form-label'>REASON</label>
                  <select
                    className='form-control'
                    value={reason}
                    onChange={this.handleSelectInputChange}
                  >
                    <option value='ADVISORY'>I am interested in investment advisory service</option>
                    <option value='ACCREDITATION'>I am interested in accreditation service</option>
                    <option value='OTHER'>I have other questions</option>
                  </select>
                </div>
                <div className='contact-form-input'>
                  <label className='contact-form-label'>MESSAGE</label>
                  <textarea
                    rows='6'
                    className='form-control'
                    onChange={this.handleTextAreaChange}
                    value={message}
                    required/>
                </div>
                <button 
                  id='contact-send-button'
                >{sendButtonText}</button>
              </form>  
            </div>
            <div id='contact-right-side'>
              <h4>CONTACT</h4>
              <span id='contact-email'>contact@daiadvisors.com</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;