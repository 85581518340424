import React from 'react';
import autoBind from 'react-autobind';
import './submenu.css';

class Submenu extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  // takes in linktype and determines whether it is highlighted based on props
  highlightedLink(linkType) {
    return this.props.highlight === linkType ? true : false;
  }

  renderLink(link) {
    return (
      <li>
        <a href="#"
          className={this.highlightedLink(link) ? 'active' : ''}
          onClick={(e) => this.props.handleMenuClick(link)}
        >
          {link}
        </a>
      </li>
    );
  }
  
  render() {
    return (
      <div className='dai-advisors-submenu'>
        <ul className='dai-advisors-submenu-list'>
          {this.props.links.map((link) => {
            return this.renderLink(link);
          })}
        </ul>
      </div>)
  }
}

export default Submenu;