import React from 'react';
import autoBind from 'react-autobind';
import './navBar.css';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  // takes in linktype and determines whether it is highlighted based on props
  highlightedLink(linkType) {
    return this.props.highlight === linkType ? true : false;
  }
  
  render() {
    return (
      <div id='dai-advisors-nav-bar'>
        <ul id='dai-advisors-nav-list'>
          <li id='nav-bar-logo'><a id='nav-bar-logo-link' href="/"><img src="/Logos/Logo3_blue.jpg" /></a></li>
          <li>
            <a href="/contact" className={this.highlightedLink('CONTACT') ? 'active' : ''}>
              Contact
            </a>
          </li>
          <li>
            <a href="https://forum.daiadvisors.com" className={this.highlightedLink('FORUM') ? 'active' : ''}>
              Forum
            </a>
          </li>
          <li>
            <a href="/services" className={this.highlightedLink('SERVICES') ? 'active' : ''}>
              Services
            </a>
          </li>
          <li>
            <a href="/about" className={this.highlightedLink('ABOUT') ? 'active' : ''}>
              About
            </a>
          </li>
          <li>
            <a href="/" className={this.highlightedLink('HOME') ? 'active' : ''}>
              Home
            </a>
          </li>
        </ul>
      </div>)
  }
}

export default NavBar;