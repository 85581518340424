import React from 'react';
import autoBind from 'react-autobind';
import { NavBar, Submenu } from '../';
import './about.css'

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenuItem: 'Our Team'
    }
    autoBind(this);
  }

  renderActiveMenuItemView() {
    let { activeMenuItem } = this.state;
    
    switch(activeMenuItem) {
      case 'Our Mission':
        return null;
      case 'Our Team':
        return (
          <div className='dai-advisors-about-section'>
            <div className='dai-advisors-bio-img'>
              <img id='dai-advisors-headshot' src='George_Head.jpg' />
            </div>
            <div className='dai-advisors-bio-text'>
              <h2 id='bio-name'>George Dai, <span id='bio-title'><i>PhD, Principal</i></span></h2>
              <br/>
              <p id='bio-description'>
                George started DAI Advisors (Dependable Alternative Investment, LLC) at the beginning of 2019 with a
                philosophy to exploit the relatively unknown area of alternative investments. George began investment
                more than 20 years ago in the public equity market. Five years ago, his interest shifted to also include
                alternative investments, with the emphasis of commercial real estate funds, besides private equity
                funds, venture capital and venture debt funds, and distressed debt funds. He has done thorough due
                diligence for a list of more than 50 such funds. George also brought more than 10 funds into Fidelity’s
                Alternative Investment Platforms. His approach in portfolio management includes optimal risk/reward
                balance, arbitrage strategies and optimal tax saving strategies.
              </p>
            </div>
          </div>
        );
    }
  }

  isMenuItemHighlighted(menuItem) {
    return menuItem === this.state.activeMenuItem;
  }

  handleMenuClick(menuItem) {
    this.setState({activeMenuItem: menuItem});
  }
  
  render() {
    let { activeMenuItem } = this.state; 

    return (
      <div>
        <NavBar highlight='ABOUT' />
        <div className='dai-advisors-page' id='dai-advisors-about'>
          <div className='splash-img' id='about-splash-img'>
            <h1 className='splash-img-title'>About Us</h1>
          </div>
          <Submenu
            highlight={activeMenuItem} links={['Our Mission', 'Our Team']}
            handleMenuClick={this.handleMenuClick}
          />
          {this.renderActiveMenuItemView()}
        </div>
      </div>
    );
  }
}

export default About;