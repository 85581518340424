import React from 'react';
import autoBind from 'react-autobind';
import { NavBar, Submenu } from '../';
import './services.css'

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenuItem: 'Investment Advisory'
    }
    autoBind(this);
  }

  renderActiveMenuItemView() {
    let { activeMenuItem } = this.state;
    
    switch(activeMenuItem) {
      case 'Investment Advisory':
        return (
          <div className='container' id='services-investment-advisory'>
            <div className='row' id='investment-advisory-row'>
              <div className='col-md-4 investment-advisory-panel'>
                <h3>Who We Are</h3>
                <br/>
                <p>
                  We are registered investment advisors who are highly experienced in both traditional and alternative
                  asset classes. We are dedicated to providing personalized advice to our clients to optimize the
                  risk/return objectives, with a wide range of consideration that includes tax and estate planning
                  considerations.
                </p>
              </div>
              <div className='col-md-4 investment-advisory-panel'>
                <h3>What We Do</h3>
                <br/>
                <p>
                  Using the most up-to-date academic research results, we consider both traditional and alternative asset 
                  classes as truly investable in modern portfolio management, thereby reducing the risk of dramatic swings 
                  of public markets. The goal is to beat or match the public market while keeping volatility low.
                </p>
              </div>
              <div className='col-md-4 investment-advisory-panel'>
                <h3>How We Do It</h3>
                <br/>
                <p>
                  Working with individual investors, we gather financial status and needs for each investor by means of 
                  investment policy statement questionnaire, and apply proprietary models to come up with a range for each asset 
                  class. The balance between traditional and alternative asset classes is based on liquidity mandate.
                </p>
              </div>
            </div>
            <div id='investment-advisory-text'>
              <h3>A New Way of Modern Investment</h3>
              <br/>
              <p>
                DAI Advisors was founded in 2019 by a team of registered investment advisors with a unique vision in
                mind: to optimize the risk/return of our clients’ investments by personalized asset allocation among
                various asset classes in both traditional and alternative investments. As an independent firm, we are
                dedicated to providing personalized investment advice to our clients, and we pride ourselves on
                fostering relationships based on trust, unparalleled service, and integrity. Through our team’s
                commitment to upholding the highest ethical and professional standards, we provide you with
                personalized advice tailored to your needs.
              </p>
              <p>To learn more, please <a href='/contact'>contact us</a>.</p>
            </div>
          </div>
        );
      case 'Accreditation Verification':
        return null;
    }
  }

  isMenuItemHighlighted(menuItem) {
    return menuItem === this.state.activeMenuItem;
  }

  handleMenuClick(menuItem) {
    this.setState({activeMenuItem: menuItem});
  }
  
  render() {
    let { activeMenuItem } = this.state; 

    return (
      <div>
        <NavBar highlight='SERVICES' />
        <div className='dai-advisors-page' id='dai-advisors-services'>
          <div className='splash-img' id='services-splash-img'>
            <h1 className='splash-img-title'>Services</h1>
          </div>
          <Submenu
              highlight={activeMenuItem}
              links={['Investment Advisory', 'Accreditation Verification']}
              handleMenuClick={this.handleMenuClick}
            />
            {this.renderActiveMenuItemView()}
        </div>
      </div>
    );
  }
}

export default Services;